import { useState } from "react";
import axiosClient from "../../api/api";
import CustomTextInut from "../CustomInputs/CustomTextInut";
import { ToastContainer } from "react-toastify";
import { successToast, errorToast } from "../../adminPanel/components/toastAlerts/ToastAlerts";
import { useTranslation } from "react-i18next";

//images
import logo from "../../img/phoneLogo.png";
//icons
import { BsX, BsEnvelope } from "react-icons/bs";

const ForgetPassword = ({
  handleFpToggle,
}) => {
  //translations\
  const { t } = useTranslation();
  //email
  const [email, setEmail] = useState({
    email: "",
  });

  //login api
  const handleForgetPassordApi = async () => {
    try {
      const res = await axiosClient.post(`/Auth/forgot_post`, email);
      if (res) {
        successToast(t("password_sent"));
      }
    } catch (err) {
      errorToast(t("user_do_not_exists"))
    }
  };

  //email handler
  const handleInputEmail = (value, inputName) => {
    setEmail((prev) => ({ ...prev, [inputName]: value }));
  };

  return (
    <>
      <div
        className="w-screen h-screen bg-black bg-opacity-80 fixed top-0 left-0
    z-50 flex items-center justify-center"
      >
        <div
          className="w-[500px] bg-white rounded-xl p-5 flex
        justify-center flex-col gap-10 relative sm:w-full sm:mx-3"
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <img src={logo} className="w-10" />
              <h2
                className="font-firago font-bold text-black_900_e0  sm:text-[18px] 
        md:text-[24px] text-[28px]"
              >
                {t("recover_password")}
              </h2>
            </div>
            <div
              onClick={handleFpToggle}
              className="w-[36px] h-[36px] rounded-full flex items-center justify-center bg-white
                    cursor-pointer shadow-[rgba(0,0,0,0.05)_0px_6px_24px_0px,rgba(0,0,0,0.08)_0px_0px_0px_1px]"
            >
              <BsX className="h-full w-full text-[24px] text-[#FF453A]" />
            </div>
          </div>
          <div className="w-full flex flex-col gap-5">
            <CustomTextInut
              PrefixIcon={BsEnvelope}
              placeholder={"email"}
              inputName={"email"}
              inputSetter={handleInputEmail}
              type="email"
            />
            <button
              onClick={handleForgetPassordApi}
              className="common-pointer cursor-pointer font-semibold text-[17px] text-center  w-[100%]
          rounded-radius11 py-4 bg-indigo_400 outline outline-[1px] outline-black_900_0a shadow-bs text-white_A700"
            >
              {t("send")}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default ForgetPassword;
