import { useState, useContext } from "react";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import { Img } from "../../components/Img";
import axiosClient from "../../api/api";
import Invoice from "../../components/Invoice/Invoice";
import { PrimmaryContext } from "../../components/MainContext/MainContext";
import {
  errorToast,
  successToast,
} from "../../adminPanel/components/toastAlerts/ToastAlerts";
import { useTranslation } from "react-i18next";
import DecDetails from "../DecDetails/DecDetails";
import AddCurriorOnPackage from "../AddCurriorOnPackage/AddCurriorOnPackage";

//images
import chinaImg from "../../img/countryFlags/china.png";
import turkeyImg from "../../img/countryFlags/turkey.png";
import usaImg from "../../img/countryFlags/usa.png";
import greeceImg from "../../img/countryFlags/greece.png";
import uk from "../../img/countryFlags/uk.png";

//icons
import { AiOutlineInbox } from "react-icons/ai";
import { BsNewspaper, BsShield, BsTag, BsTruck } from "react-icons/bs";
import { FaBalanceScale, FaScroll } from "react-icons/fa";
import { TbPlaneDeparture, TbPlaneArrival } from "react-icons/tb";

const InstockIemsCard = ({ data, setUpdatePackData }) => {
  //translations
  const { t } = useTranslation();
  //show invoice
  const [showInvoice, setShowInvoice] = useState(false);
  //package details
  const [arrPackageDetails, setArrPackageDetails] = useState(false);
  //show details
  const [showDetails, setShowDetails] = useState(false);

  //package details handlers
  const handleArrPackageDetails = () => {
    setArrPackageDetails((prev) => !prev);
  };
  //payment
  const { setRenewMoneyData } = useContext(PrimmaryContext);

  //handle pay
  const handlePay = () => {
    axiosClient
      .get(`/Pack/pay/${data.id}`)
      .then((res) => {
        setUpdatePackData(res);
        setRenewMoneyData(res);
        successToast(t("payment_success"));
      })
      .catch((err) => {
        errorToast(t("not_enough_money"));
      });
  };

  return (
    <div
      className="bg-white_A700 cursor-pointer flex flex-col items-start justify-center dark:outline-none
        outline outline-[2px] outline-white_A700 rounded-radius13 shadow-bs5 w-[100%] dark:bg-[#171719]"
    >
      <div
        className="border-black_900_14 border-solid border-b-[1px] flex flex-col dark:border-[#aeaeb2]
          items-center justify-start p-[16px] w-[100%]"
      >
        <div className="flex items-center justify-between w-[100%]">
          <div
            className="flex items-center justify-center text-center w-[auto]
                    text-black_900_99 gap-1"
          >
            <TbPlaneDeparture className="mr-[10px] text-center text-indigo_400 font-semibold" />
            <div
              className="bg-transparent cursor-pointer font-normal not-italic 
                    text-[15px] text-black_900_99 text-left"
            >
              <span className="sm:hidden dark:text-[#c7c7c7]">
                {t("flight")}{" "}
              </span>
              <span className="font-bold text-indigo_400">
                {data.flight_number}
              </span>
            </div>
          </div>
          {data.gansabaj !== 0 && data.gansabaj === 1 ? (
            <Text
              className="font-semibold text-red_A400 text-left w-[auto]"
              variant="body6"
            >
              {t("clearance_0")}
            </Text>
          ) : (
            data.gansabaj === 2 && (
              <Text
                className="font-semibold text-green-500 text-left w-[auto]"
                variant="body6"
              >
                {t("clearance_1")}
              </Text>
            )
          )}
          {data?.yviteli === 1 && (
            <div className="relative group">
              <Text
                className="font-semibold text-yellow-500 text-left w-[auto] sm:w-[49%] "
                variant="body6"
              >
                {t("yellow")}
              </Text>
              <div
                className="w-52  p-2 rounded-xl hidden group-hover:block absolute bottom-[105%] right-0 bg-white
             border border-[#e0e0e6] dark:text-[#c7c7c7] dark:bg-[#171719]"
              >
                <p className="text-[12px]">{t("yellowCautionText")}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="flex sm:flex-wrap items-center justify-between
          p-[16px] w-[100%] md:items-start sm:items-start sm:gap-2"
      >
        <div className="flex flex-row gap-[16px] items-center justify-start w-[auto]">
          <Button
            className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
            variant={"icbFillGray6001e"}
            shape={"circular"}
          >
            <AiOutlineInbox
              className={`font-semibold text-black_900_99 dark:text-[#c7c7c7]`}
            />
          </Button>
          <div className="flex flex-col items-start justify-start w-[auto]">
            <Text
              className="font-semibold text-indigo_400 text-left w-[auto]"
              variant="body5"
            >
              {data && data.tracking_number}
            </Text>
          </div>
        </div>
        <div className="flex gap-2 items-center justify-end md:self-stretch sm:self-stretch">
          <Button
            className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
            variant={
              data.addon_security ? "icbFillTealA70014" : "icbFillGray6001e"
            }
            shape={"circular"}
          >
            <BsShield
              className={`font-semibold
                                ${
                                  data.addon_security
                                    ? "text-teal_A700"
                                    : "text-black_900_99 dark:text-[#c7c7c7]"
                                }`}
            />
          </Button>
          <Button
            onClick={handleArrPackageDetails}
            className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
            size="mdIcn"
            variant={
              data.addon_transportation
                ? "icbFillTealA70014"
                : "icbFillGray6001e"
            }
            shape={"circular"}
          >
            <BsTruck
              className={`font-semibold
                                ${
                                  data.addon_transportation
                                    ? "text-teal_A700"
                                    : "text-black_900_99 dark:text-[#c7c7c7]"
                                }`}
            />
          </Button>
          <Button
            onClick={() => {
              setShowInvoice(true);
            }}
            className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
            variant={"icbFillGray6001e"}
            shape={"circular"}
          >
            <FaScroll
              className={`font-semibold text-black_900_99 dark:text-[#c7c7c7]`}
            />
          </Button>
          <Button
            onClick={() => {
              setShowDetails(true);
            }}
            className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
            variant={"icbFillGray6001e"}
            shape={"circular"}
          >
            <BsNewspaper
              className={`font-semibold text-black_900_99 dark:text-[#c7c7c7]`}
            />
          </Button>
          {data.transport_price === data.transport_paid ? (
            <Text
              className="font-semibold text-green-500 text-left w-[auto]"
              variant="body5"
            >
              {t("payed")}
            </Text>
          ) : (
            <Button
              onClick={handlePay}
              className="cursor-pointer font-semibold min-w-[111px] text-[15px] text-center text-indigo_400 w-[auto]"
              shape="RoundedBorder11"
              size="xl"
              variant="FillIndigo40014"
            >
              {t("pay")}
            </Button>
          )}
        </div>
      </div>
      <div
        className="border-black_900_14 border-solid border-t-[1px] flex flex-col  dark:border-[#aeaeb2]
          items-center justify-start p-[16px] w-[100%]"
      >
        <div className="flex flex-row bg- black items-center justify-between md:self-stretch sm:self-stretch w-[100%]">
          <div className="flex items-center gap-4 flex-wrap sm:gap-1">
            <div
              className="flex items-center justify-center text-center w-[auto] rounded-radius16
                    px-5 py-[6px] bg-gray_600_14 text-black_900_99 gap-2 sm:gap-1 sm:px-2"
            >
              <FaBalanceScale className="text-center text-indigo_400" />
              <div
                className="bg-transparent cursor-pointer font-normal not-italic 
                    text-[15px] text-black_900_99 text-left"
              >
                <span className="sm:hidden dark:text-[#c7c7c7]">
                  {t("weight")}{" "}
                </span>
                <span className="font-semibold text-left text-green-500 w-[auto]">
                  {data.total_weight} {t("kg")}
                </span>
              </div>
            </div>
            <div
              className="flex items-center justify-center text-center w-[auto] rounded-radius16
                    px-5 py-[6px] bg-gray_600_14 text-black_900_99 gap-2 sm:gap-1 sm:px-2"
            >
              <BsTag className="mr-[10px] text-center text-indigo_400 font-semibold" />
              <div
                className="bg-transparent cursor-pointer font-normal not-italic 
                    text-[15px] text-black_900_99 text-left"
              >
                <span className="sm:hidden dark:text-[#c7c7c7]">
                  {t("transportation")}{" "}
                </span>
                <span
                  className={`font-semibold text-left w-[auto]
                                ${
                                  data.transport_price === data.transport_paid
                                    ? "text-green-500"
                                    : "text-red_A400"
                                }`}
                >
                  ₾ {data.transport_price}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Img
              src={
                data.ao_id === 5 || data.ao_id === 19
                  ? chinaImg
                  : data.ao_id === 1
                  ? turkeyImg
                  : data.ao_id === 16
                  ? usaImg
                  : data.ao_id === 21
                  ? uk
                  : greeceImg
              }
              className="h-[32px] w-[32px] object-contain"
              alt="#"
            />
            {data.ao_id === 19 && (
              <Text className="font-semibold text-indigo_400" variant="body10">
                {t("land")}
              </Text>
            )}
          </div>
        </div>
      </div>
      {arrPackageDetails && (
        <AddCurriorOnPackage
          arrPackageDetails={arrPackageDetails}
          handleArrPackageDetails={handleArrPackageDetails}
          setUpdatePackData={setUpdatePackData}
          data={data}
        />
      )}
      {showInvoice && (
        <Invoice
          showInvoice={showInvoice}
          setShowInvoice={setShowInvoice}
          data={data}
        />
      )}
      {showDetails && (
        <DecDetails
          arrPackageDetails={showDetails}
          handleArrPackageDetails={setShowDetails}
          setUpdatePackData={setUpdatePackData}
          data={data}
        />
      )}
    </div>
  );
};

export default InstockIemsCard;
