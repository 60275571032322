import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const CustomTextInut = ({
  PrefixIcon = null,
  SuffixIcon = null,
  placeholder = null,
  inputName,
  inputSetter = null,
  type = "text",
  mFill,
  pattern,
  value,
  validateFuntion = null,
  validateQuote,
  clearFields = "",
  setClearFields,
  autoComplete = "on",
  noDark,
}) => {
  //translations
  const { t } = useTranslation();
  //current value variable
  const [currentValue, setCurrentValue] = useState("");
  //we save value of focus state i this variable
  const [isFocused, setIsFocused] = useState(false);
  //if filed is empty but required we must show
  const [mustFill, setMustFill] = useState("");
  //show validate quote
  const [showValidateQuote, setShowValidateQuote] = useState(false);
  //placeholder
  const [placeholdder, setPlcaholdder] = useState(placeholder);
  //inout type
  const [inputType, setInputType] = useState(type);

  //value change handler for input
  const handleValueChange = (e) => {
    const val = e.target.value;
    if (pattern === "en") {
      let reg = /^[A-Za-z]*$/;
      if (reg.test(val)) {
        setCurrentValue(val);
      }
    } else if (pattern === "ge") {
      let reg = /^[\u10A0-\u10FF]*$/i;
      if (reg.test(val)) {
        setCurrentValue(val);
      }
    } else if (pattern === "number") {
      // let reg = /^([0-9]+)$/i;
      let reg = /^(\d+(\.\d*)?)$/;

      if (val === "") {
        setCurrentValue("");
      }
      if (val.length <= 9 && reg.test(val)) {
        setCurrentValue(val);
      }
    } else {
      setCurrentValue(val);
    }
  };

  //handle show password
  const handleShowPassword = () => {
    if (type === "password") {
      if (inputType === "password") {
        setInputType("text");
      } else {
        setInputType("password");
      }
    }
  };

  //when current value changes passed function
  //will change specific object based value
  useEffect(() => {
    if (inputSetter) {
      if (validateFuntion !== null) {
        let flag = validateFuntion(currentValue);
        if (currentValue !== "") {
          if (flag) {
            setShowValidateQuote(false);
            setPlcaholdder(placeholder);
          } else {
            setPlcaholdder(validateQuote);
            setShowValidateQuote(true);
          }
        }
      }
      inputSetter(currentValue, inputName);
    }
  }, [currentValue]);

  //whenever filed is reuired and user does not
  //filled it
  useEffect(() => {
    if (currentValue !== "") {
      setMustFill(false);
    } else {
      setMustFill(mFill);
    }
  }, [mFill, currentValue]);

  //current value
  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    }
  }, [value]);

  //clear field if nesecity
  useEffect(() => {
    if (clearFields !== "" && clearFields) {
      setCurrentValue("");
      setClearFields(false);
    }
  }, [clearFields]);

  useEffect(() => {
    setPlcaholdder(placeholder);
  }, [placeholder]);

  return (
    <div
      className={`w-[100%] bg-white_A700 p-4 rounded-xl border-solid shadow-bs border 
      ${noDark ? "" : "dark:bg-[#171719]"}
    ${
      isFocused
        ? "border-[#5856d6] outline outline-[#c9c9f6]"
        : mustFill
        ? "border-[#f61b17] outline outline-[#f7bebd]"
        : "border-bluegray_100"
    }`}
    >
      <div className="flex items-center gap-4 w-full">
        {PrefixIcon !== null && (
          <PrefixIcon
            className={`w-4 h-4 text-[#636366] ${
              noDark ? "" : "dark:text-[#c7c7c7]"
            }`}
          />
        )}
        <div className="w-full relative">
          {currentValue !== "" && (
            <p
              className={`text-[12px] absolute top-[-12px] left-0  
            ${showValidateQuote ? "text-red-500" : "text-[#5856d6]"}`}
            >
              {t(`${placeholdder}`)}
            </p>
          )}
          <input
            name={inputName}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            value={currentValue}
            onChange={(e) => {
              handleValueChange(e);
            }}
            type={inputType}
            placeholder={t(`${placeholdder}`)}
            className={`w-full text-[#636366] border-none h-5 text-sm 
            bg-transparent ${noDark ? "" : "dark:text-[#c7c7c7]"}`}
            autoComplete={autoComplete}
          />
        </div>
        {SuffixIcon !== null && (
          <SuffixIcon
            onClick={handleShowPassword}
            className={`w-4 h-4 text-[#636366] cursor-pointer  
            ${noDark ? "" : "dark:text-[#c7c7c7]"}"`}
          />
        )}
      </div>
    </div>
  );
};

export default CustomTextInut;
